<script setup>
import Logo from '@components/Logo.vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Head, Link } from '@inertiajs/vue3'
import { onBeforeMount } from 'vue'
import { router } from '@inertiajs/vue3'
import { useABNExtrasStore } from '@stores/ABNExtrasStore.js'
import { useBNStore } from '@stores/BNStore.js'
import { useExtraServicesStore } from '@stores/ExtraServicesStore.js'
import { useFindStore } from '@stores/FindStore.js'
import { useGSTStore } from '@stores/GSTStore.js'
import { usePaymentStore } from '@stores/PaymentStore.js'
import { useRouterStore } from '@stores/RouterStore.js'
import { useABNCancellationStore } from '@stores/ABNCancellationStore.js'
import { useGSTCancellationStore } from '@stores/GSTCancellationStore.js'
import { useBNCancellationStore } from '@stores/BNCancellationStore.js'
import LogoPartners from '../components/LogoPartners.vue'
import PartnersSection from '../components/PartnersSection.vue'
import { useABNStore } from '@stores/ABNStore.js'
import { useABNUpdateDetailsStore } from '@stores/ABNUpdateDetailsStore.js'

const findStore = useFindStore()
const paymentStore = usePaymentStore()
const storeBN = useBNStore()
const storeExtraServices = useExtraServicesStore()
const storeExtras = useABNExtrasStore()
const storeGST = useGSTStore()
const routerStore = useRouterStore()
const abnCancellationStore = useABNCancellationStore()
const gstCancellationStore = useGSTCancellationStore()
const bnCancellationStore = useBNCancellationStore()
const abnStore = useABNStore()
const abnUpdateDetailsStore = useABNUpdateDetailsStore()

const navigation = {
  main: [
    { name: 'Home', href: '/', id: 'home' },
    { name: 'ABN Find', href: '/find', id: 'find' },
    { name: 'ABN Lookup', href: '/lookup', id: 'lookup' },
    { name: 'GST Status', href: '/gst-status', id: 'gst-status' },
    { name: 'ABN Register', href: '/abn-register', id: 'abn-register' },
    { name: 'GST Register', href: '/gst-register', id: 'gst-register' },
    { name: 'Business Name Register', href: '/bn-register', id: 'bn-register' },
  ],
  footer: {
    checks: {
      title: 'Check Services',
      links : [
        { name: 'ABN Find', href: '/find', id: 'find' },
        { name: 'ABN Lookup', href: '/lookup', id: 'lookup' },
        { name: 'GST Status', href: '/gst-status', id: 'gst-status' },
        { name: 'Business Name Availability', href: '/business-name', id: 'business-name' },
      ]
    },
    registers: {
      title: 'Registration Services',
      links: [
        { name: 'ABN Register', href: '/abn-register', id: 'abn-register' },
        { name: 'GST Register', href: '/gst-register', id: 'gst-register' },
        { name: 'Business Name Register', href: '/bn-register', id: 'bn-register' },
        { name: 'PAYG Register', href: '/payg-register', id: 'payg-register' },
      ],
    },
    cancellations: {
      title: 'Cancellations Services',
      links: [
        { name: 'ABN Cancellation', href: '/abn-cancellation', id: 'abn-cancellation' },
        { name: 'GST Cancellation', href: '/gst-cancellation', id: 'gst-cancellation' },
        { name: 'Business Name Cancellation', href: '/bn-cancellation', id: 'bn-cancellation' },
      ],
    },
   accounting: {
      title: 'Accounting Services',
      links: [
        { name: 'Income Tax Return', href: '/income-tax-return', id: 'income-tax-return' },
        { name: 'Payroll', href: '/payroll', id: 'payroll' },
        { name: 'Superannuation', href: '/superannuation', id: 'superannuation' },
        { name: 'Bookkeeping', href: '/bookkeeping', id: 'bookkeeping' },
        { name: 'BAS (Business Activity Statement)', href: '/business-activity-statement', id: 'bas-business-activity-statement' },
      ],
    },
    others: {
      title: 'Other Services',
      links: [
        { name: 'Xero Set Up', href: '/xero-set-up', id: 'xero-set-up' },
        { name: 'Backdate ABN / GST', href: '/backdate-abn-gst', id: 'backdate-abn-gst' },
        { name: 'Update your ABN Details', href: '/update-your-abn-details', id: 'update-your-abn-details' },
      ],
    },
    professional: {
      title: 'Professional Consultation',
      links: [
        { name: 'Consultation with a Qualified Accountant', href: '/professional-consultation', id: 'professional-consultation' }
      ],
    },
  },
}

const urlParams = new URLSearchParams(window.location.search)
const token = urlParams.get('token')
routerStore.utm_source = urlParams.get('utm_source')
const path = router.page.url.split('?')[0]
const google_gclid = urlParams.get('gclid')

if (google_gclid) {

  abnStore.setGoogleClid(google_gclid);

}

if (token) {
  await axios
    .post(`/api/abn/${token}`, {
      petition_origin: import.meta.env.VITE_PETITION_ORIGIN
    })
    .then(({data}) => {
      abnStore.restoreAllData(data)
      findStore.update(data)
      storeExtras.update(data)
      paymentStore.update(data)
      storeExtraServices.update(data)

      if(!!data.business_name) {
        storeBN.update(data)
      }
      if(!!data.gst) {
        storeGST.update(data)
      }

      if(!!data.abn_cancellation) {
        abnCancellationStore.update(data)
      }

      if(!!data.gst_cancellation) {
        gstCancellationStore.update(data)
      }

      if(!!data.bn_cancellation) {
        bnCancellationStore.update(data)
      }

      if(!!data.abn_update_details) {
        abnUpdateDetailsStore.update(data)
      }

      // TODO Verificar el redirect top details si estamos en la paginga
      // details con el parametro /details?token=token
      // reference: https://github.com/EverlyRusher/abn-register.com.au/pull/236#discussion_r1957873659

      if(path == '/details') {
        routerStore.visit('/details');
        return
      }

      if (data?.payment_status == 'succeeded') {
        const path = router.page.url.split('?')[0]
        if (
          path !== '/update'
          && path !== '/upload-id'
        ) {
          routerStore.visit('/update');
        }
      }
    })
    .catch((error) => {
      console.error('Server error:', error);
      routerStore.utm_source = null;
      routerStore.visit('/');
    });
}

const appName = import.meta.env.VITE_APP_NAME
const props = defineProps({ title: String })
</script>

<template>
  <Head :title="props.title ? `${appName} - ${props.title}` : appName"/>
  <div class="min-h-full">
    <div class="pb-64 bg-gray-900 isolate">
      <div class="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
        <div class="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>

      <Disclosure v-once as="nav" class="bg-transparent border-b border-gray-300 border-opacity-25 xl:border-none" v-slot="{ open }">
      <div class="px-2 mx-auto max-w-7xl sm:px-4 xl:px-8">
        <div class="relative flex items-center justify-between h-16 xl:border-b xl:border-gray-400 xl:border-opacity-25">
          <div class="flex items-center px-2 xl:px-0">
            <div class="flex-shrink-0">
              <Logo classes="h-12 w-auto"/>
            </div>
            <div class="hidden xl:ml-10 xl:block">
              <div class="flex space-x-4">
                <Link
                    v-for="item in navigation.main"
                    :key="item.name"
                    :id="`link-${item.id}-main`"
                    :href="item.href"
                    >
                    <span :class="[$page.url === item.href ? 'bg-gray-900 text-white border border-white/50' : 'text-white hover:bg-gray-900 hover:bg-opacity-50', 'rounded-md py-2 px-3 text-sm font-medium bg-opacity-75']">
                      {{ item.name }}
                    </span>
                </Link>
              </div>
            </div>
          </div>
          <div class="flex xl:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton class="inline-flex items-center justify-center p-2 text-gray-200 bg-gray-900 rounded-md hover:bg-gray-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-900">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block w-6 h-6" aria-hidden="true" />
            <XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="xl:hidden grid">
      <div class="px-2 pt-2 pb-3 flex flex-col space-y-1">
        <Link
            v-for="item in navigation.main"
            :key="item.name"
            :id="`link-${item.id}-main-mobile`"
            :href="item.href"
            >
            <span :class="[$page.url === item.href ? 'bg-gray-900 text-white border-white border-l-2' : 'text-white hover:bg-gray-900 hover:bg-opacity-50', 'block py-2 px-3 text-sm font-medium bg-opacity-75']">
              {{ item.name }}
            </span>
        </Link>
      </div>
      </DisclosurePanel>
      </Disclosure>
    </div>

    <main id="main" class="-mt-32">
      <div class="px-0 pb-12 mx-auto max-w-7xl sm:px-6 xl:px-8">
        <slot/>
      </div>
    </main>

    <footer aria-labelledby="footer-heading">
      <div class="bg-gray-100">
        <div class="grid grid-cols-1 gap-4 px-6 py-8 mx-auto max-w-7xl sm:py-12 md:grid-cols-2">
          <PartnersSection/>
          <LogoPartners/>
        </div>
      </div>
      <div class="bg-gray-900" >
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="px-6 py-8 sm:py-12 mx-auto max-w-7xl xl:px-8">
          <div class="w-full grid grid-cols-1 sm:grid-cols-6 items-start">
            <Logo classes="h-16 mb-8 sm:mb-0"/>
            <p class="text-sm text-gray-300 leading-6 sm:col-span-5">
              AGILE REGISTRATIONS PTY LTD (ACN: 672908164) owns and manages this website and its services.
              Our Australian firm excels in assisting Australians to launch, develop, and manage their businesses.
              We are supported by an independent, external registered ASIC agent and Accredited Tax Agent (license No. 25458636),
              who is legally authorised by the Australian Taxation Office and Tax Practitioners Board to offer ABN Registration services,
              independent of the Commonwealth Government. Leveraging cutting-edge technology and exceptional customer service,
              we've supported over 300,000 Australians in their business ventures.
            </p>
          </div>
          <div class="w-full mt-10">
            <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4">
              <nav
                v-for="nav_item in navigation.footer"
                :key="nav_item.title"
                class="flex flex-col gap-4"
                :aria-label="nav_item.title"
              >
                <div class="flex flex-col gap-2">
                  <h3 class="text-sm font-bold text-gray-300 leading-6">
                    {{ nav_item.title }}
                  </h3>

                  <div class="flex flex-col gap-2">
                    <Link
                      v-for="item in nav_item.links"
                      :key="item.name"
                      :id="`link-${item.id}-footer`"
                      :href="item.href"
                      class="text-sm text-gray-300 leading-6 hover:text-white"
                    >
                      > {{ item.name }}
                    </Link>
                  </div>
                </div>
              </nav>
            </div>
          </div>

          <div class="flex space-x-6 mt-12">
            <nav class="grid gap-2 sm:flex sm:justify-center sm:space-x-12"
                aria-label="Footer Terms and conditions">
              <Link
                  id="link-terms"
                  href="/terms_and_conditions"
                  class="text-sm text-gray-300 leading-6 hover:text-white">
                >
                Terms And Conditions
              </Link>
              <Link
                  id="link-privacy"
                  href="/privacy_policy"
                  class="text-sm text-gray-300 leading-6 hover:text-white">
                >
                Privacy Policy
              </Link>
              <Link
                id="link-privacy"
                href="/faq"
                class="text-sm text-gray-300 leading-6 hover:text-white">
                >
                FAQ
              </Link>
              <a href="mailto:emailing@abn-register.com.au" class="flex items-center justify-center text-sm text-gray-300 leading-6 hover:text-white">
                <svg class="w-6 mr-2 text-gray-400 h-7" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
                emailing@abn-register.com.au
              </a>
            </nav>
          </div>
          <div class="pt-8 mt-16 border-t border-white/10 sm:mt-20 xl:mt-24">
            <p class="text-xs text-gray-400 leading-5">
            &copy; 2024 AGILE REGISTRATIONS PTY LTD. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
