import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useExtraServicesStore = defineStore('storeextraservices', () => {
  // Store Models
  const xero                        = ref(false)
  const bas                         = ref(false)
  const tax_return                  = ref(false)
  const payroll                     = ref(false)
  const bookkeeping                 = ref(false)
  const superannuation              = ref(false)
  const tax_accountant_consultation = ref(false)

  const update = (abn) => {
    xero.value                        = !!abn.extra_services?.find(e => e.key === 'xero')
    bas.value                         = !!abn.extra_services?.find(e => e.key === 'bas')
    tax_return.value                  = !!abn.extra_services?.find(e => e.key === 'tax_return')
    payroll.value                     = !!abn.extra_services?.find(e => e.key === 'payroll')
    bookkeeping.value                 = !!abn.extra_services?.find(e => e.key === 'bookkeeping')
    superannuation.value              = !!abn.extra_services?.find(e => e.key === 'superannuation')
    tax_accountant_consultation.value = !!abn.extra_services?.find(e => e.key === 'tax_accountant_consultation')
  }

  return {
    xero,
    bas,
    tax_return,
    payroll,
    bookkeeping,
    superannuation,
    tax_accountant_consultation,
    update,
  }
}, { persist:true })
