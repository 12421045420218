import { defineStore } from 'pinia'
import { ref } from 'vue'


export const useABNUpdateDetailsStore = defineStore(
  'abnUpdateDetailsStore',
  () => {

    const request_personal_address = ref(false)
    const personal_address = ref('')
    const personal_address_unit_number = ref('')
    const personal_address_street_number = ref('')
    const personal_address_route = ref('')
    const personal_address_line_2 = ref('')
    const personal_address_locality = ref('')
    const personal_address_state = ref('')
    const personal_address_postal_code = ref('')
    const gPersonalAddressConfirmationBox = ref(false)

    const request_business_address = ref(false)
    const business_address = ref('')
    const business_address_unit_number = ref('')
    const business_address_street_number = ref('')
    const business_address_route = ref('')
    const business_address_line_2 = ref('')
    const business_address_locality = ref('')
    const business_address_state = ref('')
    const business_address_postal_code = ref('')
    const gBusinessAddressConfirmationBox = ref(false)

    const request_phone_number = ref(false)
    const phone_number = ref('')

    const request_email_address = ref(false)
    const email_address = ref('')

    const request_main_business_activity = ref(false)
    const main_business_activity = ref('')

    const update = (abn) => {
      const abnUpdateDetails = abn.abn_update_details

      request_phone_number.value = !!abnUpdateDetails.phone_number
      phone_number.value = abn.abn_update_details.phone_number

      request_email_address.value = !!abnUpdateDetails.email_address
      email_address.value = abn.abn_update_details.email_address

      request_main_business_activity.value =
        !!abnUpdateDetails.main_business_activity
      main_business_activity.value =
        abn.abn_update_details.main_business_activity

      // Personal address
      request_personal_address.value = !!abnUpdateDetails.personal_address
      personal_address.value = abnUpdateDetails.personal_address
      personal_address_unit_number.value = abnUpdateDetails.personal_address_unit_number
      personal_address_street_number.value = abnUpdateDetails.personal_address_street_number
      personal_address_route.value = abnUpdateDetails.personal_address_route
      personal_address_line_2.value = abnUpdateDetails.personal_address_line_2
      personal_address_locality.value = abnUpdateDetails.personal_address_locality
      personal_address_state.value = abnUpdateDetails.personal_address_state
      personal_address_postal_code.value = abnUpdateDetails.personal_address

      // Business address
      request_business_address.value = !!abnUpdateDetails.business_address
      business_address.value = abnUpdateDetails.business_address
      business_address_unit_number.value = abnUpdateDetails.business_address_unit_number
      business_address_street_number.value = abnUpdateDetails.business_address_street_number
      business_address_route.value = abnUpdateDetails.business_address_route
      business_address_line_2.value = abnUpdateDetails.business_address_line_2
      business_address_locality.value = abnUpdateDetails.business_address_locality
      business_address_state.value = abnUpdateDetails.business_address_state
      business_address_postal_code.value = abnUpdateDetails.business_address
    }

    return {
      request_personal_address,
      personal_address,
      personal_address_unit_number,
      personal_address_street_number,
      personal_address_route,
      personal_address_line_2,
      personal_address_locality,
      personal_address_state,
      personal_address_postal_code,
      gPersonalAddressConfirmationBox,
      request_business_address,
      business_address,
      business_address_unit_number,
      business_address_street_number,
      business_address_route,
      business_address_line_2,
      business_address_locality,
      business_address_state,
      business_address_postal_code,
      gBusinessAddressConfirmationBox,
      request_phone_number,
      phone_number,
      request_email_address,
      email_address,
      request_main_business_activity,
      main_business_activity,
      update
    }
  },
  { persist: true }
)
