<template>
    <div class="grid grid-cols-3 justify-items-center items-center gap-4">
      <img class="w-1/2 object-contain" src="@img/XeroLogo.webp" alt="Xero"/>
      <img class="w-3/4 object-contain" src="@img/CharteredAccountantsLogo.webp" alt="Chartered Accountants"/>
      <img class="w-1/2 object-contain" src="@img/TaxPractitionersBoardLogo.webp" alt="Tax Practitioners Board"/>
      <img class="w-1/2 object-contain" src="@img/QuickbooksLogo.webp" alt="Quickbooks"/>
      <img class="w-3/4 object-contain" src="@img/TheTaxInstituteLogo.webp" alt="The Tax Institute"/>
      <img class="w-1/2 object-contain" src="@img/MyobLogo.webp" alt="MYOB"/>
    </div>
</template>
